<div class="divide-y divide-gray-100">
  <div class="flex-none p-3">
    <h2 class="mt-3 font-semibold text-xl text-gray-900 text-center">{{item.title}}</h2>
    <p class="text-sm leading-6 text-gray-700 text-center">
      {{quickView.cu || "No Customer"}}
    </p>
    <div class="text-center">
      <span class="inline-flex items-center px-3 text-center py-0.5 rounded-full text-sm font-medium mt-1"
        [class.bg-blue-100]="!quickView.dr"
        [class.text-blue-800]="!quickView.dr"
        [class.bg-green-100]="quickView.dr"
      [class.text-green-800]="quickView.dr">{{quickView.dr ? 'Resolved' : 'Claim Date'}}: {{ (quickView.dr || quickView.cd) | dateTimeToTimezone : 'dateonly'}}</span>
    </div>
    <p class="text-sm leading-6 text-gray-700 mt-5">
      <b>Origin Company:</b>  {{quickView.oc || "No Company"}}
    </p>
    <p class="text-sm leading-6 text-gray-700">
      <b>Claim Date:</b> {{quickView.cd | dateTimeToTimezone : 'dateonly'}}
    </p>
    <p class="text-sm leading-6 text-gray-700">
      <b>Claim Amount:</b> {{quickView.ca | currency}}
    </p>
    @if (quickView.ra) {
      <p class="text-sm leading-6 text-gray-700">
        <b>Recovered Amount:</b> {{quickView.ra | currency}}
      </p>
    }
  </div>
  <div class="flex flex-auto flex-col p-6 gap-2">
    <button type="button" class="w-full rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2" *hasPermission="[permissions.ADMIN_ALL]" (click)="viewClaim()">View Claim</button>
  </div>
</div>