<div class="font-sans bg-white rounded-xl p-5 inbound-shipments-view">
  <div class="flex flex-col gap-2 md:flex-row justify-between">
    <div>
      <h2 class="mt-4 text-2xl leading-6 font-medium items-center text-gray-900">Inbound Shipments {{getTotalDeliveredShipments()}}</h2>
      <div class="flex">
        <h4 class="text-lg text-gray-600 mt-2 font-normal hover:text-blue-500 cursor-pointer w-fit" (click)="editLocation()" [matTooltip]="getLocationButtonTooltip()">
          @if (selectedLocationDetails) {
            <div>
              <mat-icon class="align-text-bottom">location_on</mat-icon>
              {{selectedLocationDetails}}
              <button type="button" class="ml-3">
                <i class="fas fa-edit"></i>
              </button>
            </div>
          }
          @if (!selectedLocationDetails) {
            <span class="block link mb-4">Select Location</span>
          }
        </h4>
        @if (selectedLocationDetails) {
          <button matTooltip="Clear Receiving Location" type="button" (click)="clearLocation()" class="ml-3 text-lg text-gray-600 mt-2 font-normal hover:text-blue-500 cursor-pointer ">
            <i class="fas fa-close"></i>
          </button>
        }
      </div>
      @if (filterTitle) {
        @for (filter of filterTitle.split(','); track filter) {
          <span class="inline-flex rounded-full items-center py-0.5 px-2.5 mr-1 text-sm font-medium bg-sky-100 text-sky-700">
            {{filter}}
          </span>
        }
      }
      @if (filterTitle) {
        <span class="inline-flex rounded-full items-center py-0.5 pl-2.5 pr-1 mr-1 text-sm font-medium bg-gray-100 text-gray-700">
          Clear All
          <button type="button" class="flex-shrink-0 ml-0.5 h-4 w-4 rounded-full inline-flex items-center justify-center text-gray-400 hover:bg-gray-200 hover:text-gray-500 focus:outline-none focus:bg-gray-500 focus:text-white" (click)="deleteDrillDown()">
            <svg class="h-2 w-2" stroke="currentColor" fill="none" viewBox="0 0 8 8">
              <path stroke-linecap="round" stroke-width="1.5" d="M1 1l6 6m0-6L1 7" />
            </svg>
          </button>
        </span>
      }
    </div>
    <div class="flex flex-col md:flex-row md:gap-2">
      <mat-form-field class="min-w-[200px]">
        <mat-label>Carrier</mat-label>
        <mat-select [(ngModel)]="selectedCarrier">
          @for (carrier of selectableCarriers; track carrier) {
            <mat-option value="{{carrier}}">{{carrier}}</mat-option>
          }
        </mat-select>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Search Deliveries</mat-label>
        <input matInput [(ngModel)]="searchTerm" #search>
        <mat-icon matSuffix class="cursor-pointer">search</mat-icon>
      </mat-form-field>
    </div>
  </div>
  <div class="grid grid-cols-12 lg:gap-x-16 grid-flow-row divide divide-gray-300">
    <ol class="mt-4 divide-y divide-gray-300 text-sm leading-6 order-2 md:order-1 md:col-span-8 col-span-12 xl:col-span-9 overflow-auto max-h-[73vh] z-10">
      @for (shipment of inboundShipments; track shipment) {
        @if (selectedCarrier=='All' || selectedCarrier==shipment.carrierName) {
          <li class="relative flex space-x-6 py-6 xl:static">
            <div class="flex-auto">
              <h3 class="pr-10 font-semibold text-gray-900 xl:pr-0 text-xl">{{shipment.carrierName}}</h3>
              <dl class="mt-2 flex flex-col text-gray-500 text-base xl:flex-row divide-x-2">
                <div class="xl:pr-3.5">
                  <div>
                    <span class="link text-lg" routerLink="/shipments/{{shipment.id}}">Load #{{shipment.id}} </span>
                    <span class="status ml-5">
                      @switch (shipment.tripStatus) {
                        @case (tripStatusEnum.Scheduled) {
                          <i class="fas fa-clock"><span>Scheduled</span></i>
                        }
                        @case (tripStatusEnum.Enroute) {
                          <i class="fas fa-shipping-fast"><span>Enroute</span></i>
                        }
                        @case (tripStatusEnum.Complete) {
                          <i class="fas fa-check"><span>Delivered</span></i>
                        }
                      }
                    </span>
                  </div>
                  @if (shipment.supplierName) {
                    <div class="font-medium">Supplier: {{shipment.supplierName}}</div>
                  }
                  @if (shipment.trackingNumber) {
                    <div class="font-medium">Tracking Number: {{shipment.trackingNumber}}</div>
                  }
                  @if (shipment.currentStatusMessage) {
                    <div>
                      @if (shipment.currentStatusDate) {
                        <span class="font-medium">{{shipment.currentStatusDate | dateTimeToTimezone:'datetime': shipment.currentStatusTimeZoneId}}</span>: 
                      }
                      {{shipment.currentStatusMessage}}</div>
                  }
                  <div class="font-gray-700 text-sm">{{shipment.destinationCompany}}</div>
                  <div>
                    <div class="flex flex-col mt-2 ml-2 inbound-shipments-items">
                      @for (item of shipment.shipmentItems; track item) {
                        <mat-checkbox class="whitespace-normal" [(ngModel)]="item.markedReceived" (ngModelChange)="receiveItem(item)">
                          <div class="whitespace-normal text-xl xl:text-base ml-2 xl:ml-0">{{item.quantity}} {{item.packaging}} @if (item.pieces && item.pieces > 0) {
                            ({{item.pieces}} pcs)
                          }- {{item.description}}</div>
                        </mat-checkbox>
                      }
                    </div>
                  </div>
                </div>
              </dl>
            </div>
            <div class="absolute top-6 right-0 xl:relative xl:top-auto xl:right-auto xl:self-center">
              <div>
                <button mat-icon-button [matMenuTriggerFor]="menu">
                  <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #menu="matMenu">
                  <div mat-menu-item class="text-gray-700 block text-sm h-auto py-3" (click)="openDocumentDetails(shipment.id, shipment.serviceType.id, shipment)">Documents</div>
                  <div mat-menu-item class="text-gray-700 block text-sm h-auto py-3" (click)="openTrackingDetails(shipment.id)">View Tracking</div>
                </mat-menu>
              </div>
            </div>
          </li>
        }
      }
    </ol>
    <div class="md:col-span-4 col-span-12 order-1 md:order-2 xl:col-span-3">
      <mat-calendar class="hidden lg:block" #calendar [(selected)]="selectedDate" [headerComponent]="customMatCalendarHeader" [dateFilter]="filterDates" (monthSelected)="checkDates($event)" id="incoming-calendar"></mat-calendar>
      <div class="block lg:hidden mt-5">
        <sn-arrow-date-selector  [allowPrevious]="true" class="flex mt-8 scale-[1.35] justify-center" (onDateChange)="dateChanged($event)" [selectedDate]="selectedDate"></sn-arrow-date-selector>
      </div>
    </div>
  </div>
</div>
