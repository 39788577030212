import { CurrencyPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnInit, inject } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { SearchItemDto } from '../../../core/services/api.service';
import { UserResolverService } from '../../../core/services/user-resolver.service';
import { Permissions } from '../../../shared/common/permissions';
import { HasPermissionDirective } from '../../directives/haspermission.directive';
import { SearchComponent } from '../search.component';
import { DateTimeToTimezonePipe } from 'app/shared/pipes/date-time-to-timezone.pipe';

@Component({
    selector: 'sn-claim-quick-view',
    templateUrl: './claim-quick-view.component.html',
    styleUrls: ['./claim-quick-view.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [HasPermissionDirective, CurrencyPipe, DateTimeToTimezonePipe]
})
export class ClaimQuickViewComponent implements OnInit {
  private _matDialog = inject(MatDialog);
  private _userResolverService = inject(UserResolverService);
  private _router = inject(Router);
  private _dialogRef = inject<MatDialogRef<SearchComponent>>(MatDialogRef);

  get searchItem(): SearchItemDto | undefined {
    return this.item;
  }
  // TODO: Skipped for migration because:
  //  Accessor inputs cannot be migrated as they are too complex.
  @Input() set searchItem(value: SearchItemDto | undefined) {
      this.item = value;
      this.quickView = JSON.parse(value?.quickActionContent ?? "");
  }

  isASMUser: boolean = false;
  item: SearchItemDto | undefined;
  quickView: any;
  permissions = Permissions;

  ngOnInit(): void {
    this._userResolverService.userInfo.subscribe(x => this.isASMUser = x.IsASM);
  }

  viewClaim() {
    this._router.routeReuseStrategy.shouldReuseRoute = () => false;
    this._router.navigate(['/claims', this.item?.referenceId]);
    this._dialogRef.close();
  }
}
