import { AfterViewInit, Component, ElementRef, OnInit, ViewEncapsulation, inject, viewChild } from '@angular/core';
import { MatCalendar, MatCalendarHeader, MatDatepickerModule } from '@angular/material/datepicker';
import { ColumnTypeEnum, FilterItemDto, FilterTypeEnum, InboundShipmentDto, InboundShipmentItemDto, LocationDto, LocationsClient, ReferenceTypeEnum, ServiceTypeEnum, ShipmentsClient, TripStatusEnum } from '../../core/services/api.service';
import { DateTime } from 'luxon';
import { RequestService } from '../../core/services/request.service';
import { UserSettingService, UserSettingsKeys } from '../../core/services/user-setting.service';
import { SpinnerService } from '../../core/spinner/spinner.svc';
import { SnackBarService } from '../../core/services/snack-bar.service';
import { fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { LocationSelectDialogComponent } from '../../shared/location-select-dialog/location-select-dialog.component';
import { LocationHelper } from '../../core/locations-helper';
import { TrackingDetailsModalComponent } from '../../shared/tracking-details-modal/tracking-details-modal.component';
import { DocumentsDialogComponent } from '../../shared/documents-dialog/documents-dialog.component';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { ArrowDateSelectorComponent } from '../../shared/widgets/arrow-date-selector/arrow-date-selector.component';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatInputModule } from '@angular/material/input';
import { MatOptionModule } from '@angular/material/core';
import { FormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ServiceTypesService } from 'app/core/services/service-types.service';
import { DateTimeToTimezonePipe } from 'app/shared/pipes/date-time-to-timezone.pipe';

@Component({
    templateUrl: './inbound-shipments.component.html',
    styleUrls: ['./inbound-shipments.component.scss'],
    encapsulation: ViewEncapsulation.None,
    imports: [MatTooltipModule, MatIconModule, MatFormFieldModule, MatSelectModule, FormsModule, MatOptionModule, MatInputModule, RouterLink, MatCheckboxModule, MatButtonModule, MatMenuModule, MatDatepickerModule, ArrowDateSelectorComponent, DateTimeToTimezonePipe]
})
export class InboundShipmentsComponent implements OnInit, AfterViewInit {
  private _shipmentsClient = inject(ShipmentsClient);
  protected _matDialog = inject(MatDialog);
  private _snackBarService = inject(SnackBarService);
  private _spinnerService = inject(SpinnerService);
  private _userSettingService = inject(UserSettingService);
  private _locationsClient = inject(LocationsClient);
  private _dialog = inject(MatDialog);
  private _requestService = inject(RequestService);
  private _route = inject(ActivatedRoute);
  private _router = inject(Router);
  private _serviceTypesService = inject(ServiceTypesService);

  readonly calendar = viewChild(MatCalendar);
  readonly search = viewChild<ElementRef>('search');
  customMatCalendarHeader = CustomMatCalendarHeader;

  _selectedDate: DateTime = DateTime.now().toUTC().set({second: 0, minute: 0, hour: 0, millisecond: 0});
  get selectedDate() {
    return this._selectedDate;
  }
  set selectedDate(value: DateTime) {
    if(this._selectedDate != value && value != null) {
      this._selectedDate = value;
      this.loadInboundShipments();
    }
  }
  selectedMonth: Number = DateTime.now().month;
  selectedYear: Number = DateTime.now().year;
  searchTerm: string = "";
  availableDates: DateTime[] = [];

  inboundShipments: InboundShipmentDto[] = [];
  inboundShipmentsLocationID: number | null = null;
  selectedLocation?: LocationDto;
  selectedLocationDetails: string = "";

  selectableCarriers: string[] = ["All"];
  selectedCarrier: string = "All";

  tripStatusEnum = TripStatusEnum;
  filterTitle: string;

  ngOnInit(): void {
    this._spinnerService.show();

    let keys = this._route.snapshot.paramMap.keys;
    keys.forEach(param => {
      let value = this._route.snapshot.paramMap.get(param);
      switch (param) {
        case "carrierName": this.selectedCarrier = value ?? "";
        // Date needs to be set to UTC midnight so that timezone is FixedZoneOffset instead of user's local time
        case "date": this._selectedDate = DateTime.fromFormat(value ?? "", "MM-dd-yyyy", {zone: 'utc'}).set({second: 0, minute: 0, hour: 0, millisecond: 0});
      }
    });

    this._userSettingService.get(UserSettingsKeys.INBOUND_SHIPMENTS_WIDGET).subscribe({
      next: x => {
        if(x) {
          var locationFilter = JSON.parse(x.value ?? "");
          if(locationFilter) {
            this.inboundShipmentsLocationID = locationFilter.value1;
            if(this.inboundShipmentsLocationID) {
              this._requestService.get( this._locationsClient.locations_Get(this.inboundShipmentsLocationID), x => {
                this.setLocation(x);
                this.calendar()?.updateTodaysDate();
              }, "Location");
            }
          }
          else {
            this.loadInboundShipments();
            this.loadAvailableDates();
          }
        }

        this._spinnerService.hide();
      },
      error: err => {
        this._snackBarService.openError("Error user settings");
        this._spinnerService.hide();
      }
    });

  }

  dateChanged(date: DateTime) {
    if(date) {
      this.selectedDate = date;
    }
  }

  ngAfterViewInit() {
    this.searchSubscribe();
  }

  searchSubscribe() {
    fromEvent(this.search()?.nativeElement, 'keyup')
    .pipe(
        debounceTime(1000),
        distinctUntilChanged(),
        tap(() => {
          this.loadAvailableDates();
          this.loadInboundShipments();
        })
    )
    .subscribe();
  }

  loadAvailableDates() {
    let filterItems: FilterItemDto[] = [];

    if(this._selectedDate) {
      filterItems.push(FilterItemDto.fromJS({
        column: "scheduledDeliveryDate",
        value1: this._selectedDate.set({second: 0, minute: 0, hour: 0, day: 1, month: parseInt(this.selectedMonth.toString()), year: parseInt(this.selectedYear.toString()) }),
        value2: this._selectedDate.set({second: 0, minute: 0, hour: 0, day: 0, month: parseInt(this.selectedMonth.toString()) + 1, year: parseInt(this.selectedYear.toString()) }),
        columnType: ColumnTypeEnum.Date,
        filterType: FilterTypeEnum.Between
      }));
    }

    var otherFitlters = this.getFilterFromQueryParam();
    filterItems.push(... otherFitlters);

    this.getLocationFilter()

    this._requestService.get( this._shipmentsClient.shipments_GetDatesForTheMonthWithInboundShipments(filterItems, this.searchTerm, this.inboundShipmentsLocationID), x => {
      this.availableDates = x;
      this.calendar()?.updateTodaysDate();
    }, "Inbound Shipments Dates");

  }

  loadInboundShipments() {
    let filterItems: FilterItemDto[] = [];

    if(this._selectedDate) {
      filterItems.push(FilterItemDto.fromJS({
        column: "scheduledDeliveryDate",
        value1: this._selectedDate,
        columnType: ColumnTypeEnum.Date,
        filterType: FilterTypeEnum.Equals
      }));
    }

    var otherFitlters = this.getFilterFromQueryParam();
    filterItems.push(... otherFitlters);
    this._requestService.get( this._shipmentsClient.shipments_GetInboundShipments(filterItems, this.searchTerm, this.inboundShipmentsLocationID), x => {
      this.inboundShipments = x;
      this.selectableCarriers = ["All", ...(x?.map(m => m.carrierName ?? "").sort((x, y) => x && y && x > y ? 1 : -1).filter(this.distinct).filter(f => !!f) ?? [])];
      if(!(this.selectedCarrier && this.selectableCarriers.includes(this.selectedCarrier))) {
        this.selectedCarrier = "All";
      }
    }, "Inbound Shipments");
  }

  distinct(value: string, index: number, self: any) {
    return self.indexOf(value) === index;
  }

  editLocation() {
    const locationSelect = this._dialog.open(LocationSelectDialogComponent, {
      width: '500px',
      disableClose: true
    });

    locationSelect.afterClosed().subscribe(result => {
      if (result) {
        this.setLocation(result);
      }
    });
  }

  setLocation(location: LocationDto | null) {
    this.selectedLocation = location ? location : undefined;
    this.selectedLocationDetails = location ? this.getLocationDetails(location) : "";
    this.inboundShipmentsLocationID = location ? location.id : null
    var locationFilter = null;

    if(this.inboundShipmentsLocationID) {
      locationFilter = FilterItemDto.fromJS({
        column: "destinationLocationID",
        value1: String(this.inboundShipmentsLocationID),
        columnType: ColumnTypeEnum.Number,
        filterType: FilterTypeEnum.Equals
      })
    }
    this._userSettingService.set(UserSettingsKeys.INBOUND_SHIPMENTS_WIDGET, JSON.stringify(locationFilter)).subscribe(x => {
      this.loadAvailableDates();
      this.loadInboundShipments();
    });
  }

  clearLocation() {
    this.setLocation(null);
  }

  getLocationDetails(location: LocationDto) {
    return LocationHelper.locationAddressDisplay(location);
  }

  getLocationFilter():  FilterItemDto | null {
    let locationFilter = null
    if(this.inboundShipmentsLocationID) {
      locationFilter = FilterItemDto.fromJS({
        column: "destinationLocationID",
        value1: String(this.inboundShipmentsLocationID),
        columnType: ColumnTypeEnum.Text,
        filterType: FilterTypeEnum.Equals
      });
    }
    return locationFilter;
  }

  filterDates = (date: DateTime): boolean => {
    if (this.availableDates !== undefined) {
      return this.availableDates.some(s => s.toUTC().toFormat('MMddyyyy') == date.toFormat('MMddyyyy')) || date.month != this.selectedMonth || date.year != this.selectedYear
    } else {
      return true;
    }
  }

  checkDates(event: DateTime) {
    if(event.month != this.selectedMonth || event.year != this.selectedYear )
    {
      this.selectedMonth = event.month;
      this.selectedYear = event.year;
      this.loadAvailableDates();
    }
  }

  receiveItem(item: InboundShipmentItemDto) {
    this._spinnerService.show();
    this._shipmentsClient.shipments_ReceiveShipmentItem(item.id, item.markedReceived).subscribe({
      next: x => {
        if(x) {
          item = x;
        }
        if(item.markedReceived) {
          this._snackBarService.open("Shipment item marked as received");
        }
        else {
          this._snackBarService.open("Shipment item marked as not received");
        }

        this._spinnerService.hide();
      },
      error: err => {
        this._snackBarService.openError("Error updating shipment item");
        this._spinnerService.hide();
      }
    })
  }

  openTrackingDetails(loadNumber: number) {
    this._matDialog.open(TrackingDetailsModalComponent, {
      width: '65%',
      data: {loadNumber: loadNumber}
    });
  }

  openDocumentDetails(loadNumber: number, serviceType: ServiceTypeEnum, shipment: any) {
    this._matDialog.open(DocumentsDialogComponent, {
      data: {
        loadNumber: loadNumber,
        serviceType: serviceType,
        referenceType: ReferenceTypeEnum.Shipment,
        shipment: shipment
      }
    });
  }

  getFilterFromQueryParam() {
    var filters: FilterItemDto[] = [];
    const keys = this._route.snapshot.queryParamMap.keys;
    
    for (const element of keys) {
      switch (element) {
        case 'serviceTypes':
          const selectedServiceTypesIndex = this._route.snapshot.queryParamMap.get(element);
          if (!this._serviceTypesService.isAllServiceTypeSelected(selectedServiceTypesIndex)) {
            let filterItem = FilterItemDto.fromJS({
              column: "serviceTypeID",
              value1: selectedServiceTypesIndex,
              filterType: FilterTypeEnum.Contains,
              columnType: ColumnTypeEnum.SelectNumeric
            });
            filters.push(filterItem)

            const serviceTypeIds: number[] = selectedServiceTypesIndex.split(",").map(Number);

            for (const serviceTypeId of serviceTypeIds) {
              var filterName = this._serviceTypesService.getEnumValueByIndex(serviceTypeId);

              if (!filterName) {
                continue;
              }

              if (this.filterTitle && this.filterTitle.length > 1 && !this.filterTitle.split(',').includes(filterName)) {
                this.filterTitle += `, ${this._serviceTypesService.getEnumValueByIndex(serviceTypeId)}`;
              } else {
                this.filterTitle = `${this._serviceTypesService.getEnumValueByIndex(serviceTypeId)}`;
              }
            }

          }
          break;
        default:
          break;
      }
    };

    return filters;
  }
  
  getLocationButtonTooltip() {  
    return this.selectedLocationDetails ? "Edit Receiving Location" : "Select a location";
  }

  deleteDrillDown() {
    this.filterTitle = "";
    // we only have serviceTypes query parameter
    var url = this._router.url.split('?')[0];
    var url = decodeURIComponent(this._router.url.split('?')[0]);
    // Navigate to the same route with updated query parameters
    this._router.navigate([url]);
    this.ngOnInit();
  }

  getTotalDeliveredShipments(){
    let count = this.inboundShipments.filter(x => (this.selectedCarrier == 'All' || this.selectedCarrier == x.carrierName));
    let totalCarrier = count.filter(x => x.tripStatus == TripStatusEnum.Complete).length;
    return "("+ totalCarrier + " of " + count.length + " Delivered)";
  }
}

@Component({
    selector: 'mat-calendar-header',
    template: `
  <div class="mat-calendar-header">
  <div class="mat-calendar-controls">
    <button mat-button type="button" class="mat-focus-indicator mat-calendar-period-button mat-button mat-button-base"
            (click)="currentPeriodClicked()" [attr.aria-label]="periodButtonLabel"
            cdkAriaLive="polite">
      <span class="mat-button-wrapper">
          <span id="mat-calendar-button-0">{{periodButtonText}}</span>
          <svg viewBox="0 0 10 5" focusable="false" class="mat-calendar-arrow" [class.mat-calendar-invert]="calendar.currentView != 'month'">
              <polygon points="0,0 5,5 10,0"></polygon>
          </svg>
      </span>
      <span matripple class="mat-ripple mat-button-ripple"></span>
      <span class="mat-button-focus-overlay"></span>
    </button>

    <div class="mat-calendar-spacer"></div>

    <button mat-icon-button type="button" class="mat-calendar-previous-button mat-focus-indicator mat-icon-button mat-button-base"
            [disabled]="!previousEnabled()" (click)="customPrev()"
            [attr.aria-label]="prevButtonLabel">
      <span class="mat-button-wrapper"></span>
      <span matripple class="mat-ripple mat-button-ripple mat-button-ripple-round"></span>
      <span class="mat-button-focus-overlay"></span>
    </button>

    <button mat-icon-button type="button" class="mat-calendar-next-button mat-focus-indicator mat-icon-button mat-button-base"
            [disabled]="!nextEnabled()" (click)="customNext()"
            [attr.aria-label]="nextButtonLabel">
      <span class="mat-button-wrapper"></span>
      <span matripple class="mat-ripple mat-button-ripple mat-button-ripple-round"></span>
      <span class="mat-button-focus-overlay"></span>
    </button>
  </div>
</div>

`,
    standalone: false
})
export class CustomMatCalendarHeader extends MatCalendarHeader<any> {

  /** Handles user clicks on the period label. */
  currentPeriodClicked(): void {
    this.calendar.currentView = this.calendar.currentView == 'month' ? 'multi-year' : 'month';
  }

  /** Handles user clicks on the previous button. */
  customPrev(): void {
    this.checkMonth(-1);
    this.previousClicked();
  }

  /** Handles user clicks on the next button. */
  customNext(): void {
    this.checkMonth(1);
    this.nextClicked()
  }

  checkMonth(days: number) {
    if(this.calendar.currentView == 'month')
    {
      this.calendar.monthSelected.emit(this.calendar.activeDate.set({month:this.calendar.activeDate.month + days}))
    }
  }

}
