import { CommonModule } from '@angular/common';
import { Component, OnInit, inject } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule, PageEvent } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Router } from '@angular/router';
import { LuxonModule } from 'luxon-angular';
import { CarrierListDto, EdiClient, EdiFileFilterDto, EdiFileListDto, EdiShipmentDataDto } from '../../core/services/api.service';
import { RequestService } from '../../core/services/request.service';
import { EdiLoadSelectionDialogComponent } from './edi-load-selection-dialog.component';

@Component({
    selector: 'sn-edi-files',
    standalone: true,
    imports: [
        CommonModule,
        FormsModule,
        MatButtonModule,
        MatCardModule,
        MatDatepickerModule,
        MatDialogModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatPaginatorModule,
        MatSelectModule,
        MatTableModule,
        MatTooltipModule,
        LuxonModule
    ],
    templateUrl: './edi-files.component.html',
    styleUrls: ['./edi-files.component.scss']
})
export class EdiFilesComponent implements OnInit {
    private _ediClient = inject(EdiClient);
    private _requestService = inject(RequestService);
    private _matDialog = inject(MatDialog);
    private _router = inject(Router);

    files: EdiFileListDto[] = [];
    carriers: CarrierListDto[] = [];
    totalCount = 0;
    loadNumberSearch: number | null = null;
    displayedColumns = ['filePath', 'carrierKey', 'totalLoads'];

    filter: EdiFileFilterDto = {
        pageNumber: 1,
        pageSize: 100,
        carrierKey: '',
        sortField: 'receivedDate',
        sortAscending: false,
        init: () => {},
        toJSON: () => ({
            pageNumber: this.filter.pageNumber,
            pageSize: this.filter.pageSize,
            carrierKey: this.filter.carrierKey,
            sortField: this.filter.sortField,
            sortAscending: this.filter.sortAscending,
        })
    };

    ngOnInit() {
        this.loadCarriers();
        this.loadFiles();
    }

    loadCarriers() {
        this._requestService.get(
            this._ediClient.edi_GetEdiCarriers(),
            carriers => this.carriers = carriers,
            'EDI Carriers'
        );
    }

    loadFiles() {
        this._requestService.get(
            this._ediClient.edi_GetEdiFiles(this.filter),
            response => {
                this.files = response.records;
                this.totalCount = response.count;
            },
            'EDI Files'
        );
    }

    onFilterChange() {
        this.filter.pageNumber = 1;
        this.loadFiles();
    }

    onSort(field: string) {
        if (this.filter.sortField === field) {
            this.filter.sortAscending = !this.filter.sortAscending;
        } else {
            this.filter.sortField = field;
            this.filter.sortAscending = true;
        }
        this.loadFiles();
    }

    onPageEvent(event: PageEvent) {
        this.filter.pageNumber = event.pageIndex + 1;
        this.filter.pageSize = event.pageSize;
        this.loadFiles();
    }

    onFileClick(file: EdiFileListDto) {
        this._router.navigate(['/developers/edi/files', file.filePath]);
    }

    onLoadNumberSearch() {
        if (this.loadNumberSearch) {
            this._requestService.get(
                this._ediClient.edi_GetEdiData(this.loadNumberSearch),
                (data: EdiShipmentDataDto[]) => {
                    if (data.length === 0) {
                        // No results found
                        return;
                    } else if (data.length === 1) {
                        // Single result - route directly
                        const load = data[0];
                        this._router.navigate(['/developers/edi/files', load.filePath, load.loadNumber]);
                    } else {
                        // Multiple results - show selection dialog
                        this._matDialog.open(EdiLoadSelectionDialogComponent, {
                            data: { loads: data },
                            width: '600px'
                        }).afterClosed().subscribe(result => {
                            if (result) {
                                this._router.navigate(['/developers/edi/files', result.filePath, result.loadNumber]);
                            }
                        });
                    }
                },
                'EDI Load Data'
            );
        }
    }
} 