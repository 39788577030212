import { CommonModule } from '@angular/common';
import { Component, OnInit, inject } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { ActivatedRoute } from '@angular/router';
import { EdiClient, EdiShipmentDataDto } from '../../../core/services/api.service';
import { RequestService } from '../../../core/services/request.service';

@Component({
    selector: 'sn-edi-load-detail',
    templateUrl: './edi-load-detail.component.html',
    standalone: true,
    imports: [CommonModule, MatCardModule]
})
export class EdiLoadDetailComponent implements OnInit {
    private route = inject(ActivatedRoute);
    private ediClient = inject(EdiClient);
    private requestService = inject(RequestService);

    data: EdiShipmentDataDto | undefined;
    filePath: string | undefined;
    loadNumber: string | undefined;

    ngOnInit(): void {
        this.route.params.subscribe(params => {
            this.filePath = params['fileName'];
            this.loadNumber = params['loadNumber'];

            if (this.filePath && this.loadNumber) {
                this.loadData();
            }
        });
    }

    private loadData(): void {
        if (!this.filePath || !this.loadNumber) return;

        this.requestService.get(
            this.ediClient.edi_GetEdiData(parseInt(this.loadNumber), "", this.filePath),
            (response) => {
                if (response && response.length > 0) {
                    this.data = response[0];
                }
            },
            'EDI Load Data'
        );
    }

    getMainDetails(): { key: string; value: any; type: string }[] {
        if (!this.data) return [];

        const details = [
            { key: 'Load Number', value: this.data.loadNumber, type: 'number' },
            { key: 'File Path', value: this.data.filePath, type: 'string' },
            { key: 'File Name', value: this.data.fileName, type: 'string' },
            { key: 'Load Type', value: this.data.loadType, type: 'string' },
            { key: 'Result', value: this.data.result, type: 'string' },
            { key: 'Result Message', value: this.data.resultMessage, type: 'string' },
            { key: 'Carrier Key', value: this.data.carrierKey, type: 'string' },
            { key: 'Carrier Name', value: this.data.carrierName, type: 'string' },
            { key: 'Bill Number', value: this.data.billNumber, type: 'string' },
            { key: 'PO Number', value: this.data.poNumber, type: 'string' },
            { key: 'EDI Cost', value: this.data.ediCost, type: 'number' },
            { key: 'Variance', value: this.data.variance, type: 'number' },
            { key: 'Original Carrier Cost', value: this.data.originalCarrierCost, type: 'number' },
            { key: 'New Carrier Cost', value: this.data.newCarrierCost, type: 'number' },
            { key: 'Original Tracking Cost', value: this.data.originalTrackingCost, type: 'number' },
            { key: 'New Tracking Cost', value: this.data.newTrackingCost, type: 'number' },
            { key: 'Original Customer Cost', value: this.data.originalCustomerCost, type: 'number' },
            { key: 'New Customer Cost', value: this.data.newCustomerCost, type: 'number' },
            { key: 'Original Weight', value: this.data.originalWeight, type: 'number' },
            { key: 'New Weight', value: this.data.newWeight, type: 'number' },
            { key: 'Is Reclass', value: this.data.isReclass, type: 'boolean' },
            { key: 'Billed Date', value: this.data.billedDate, type: 'date' },
            { key: 'Tracking Cost Updated Date', value: this.data.trackingCostUpdatedDate, type: 'date' },
            { key: 'Email Sent Date', value: this.data.emailSentDate, type: 'date' },
            { key: 'Carrier Increase Percentage', value: this.data.carrierIncreasePercentage, type: 'number' },
            { key: 'Customer Increase Percentage', value: this.data.customerIncreasePercentage, type: 'number' },
            { key: 'Weight Variance', value: this.data.weightVariance, type: 'number' },
            { key: 'Is Redimension', value: this.data.isRedimension, type: 'boolean' }
        ];

        return details.filter(detail => detail.value !== undefined && detail.value !== null);
    }
} 