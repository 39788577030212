@if (userInfo) {
<nav class="sticky top-0 w-full bg-gray-800 z-20 font-sans text-white" id="main-nav">
  <div class="absolute inset-0 shadow pointer-events-none" aria-hidden="true"></div>
  <div class="relative z-50">
    <div class="flex justify-between items-center px-4 py-5 lg:py-3 lg:justify-start">
      <img class="logo" src="https://sastatusnowprdeast.blob.core.windows.net/images/snlogo.svg" />
      <div class="-mr-2 -my-2 lg:hidden">
        <button type="button"
          class="inline-flex items-center p-1 border border-transparent rounded-full shadow-sm text-black bg-gray-100 hover:bg-blue-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
          <mat-icon class="cursor-pointer" matTooltip="System Wide Search. Tip: Use CTRL+S to launch search"
            (click)="showSearch()">search</mat-icon>
        </button>
        <button type="button"
          class="rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-700"
          aria-expanded="false" (click)="showMobileMenu = !showMobileMenu">
          <span class="sr-only">Open menu</span>
          @if (!showMobileMenu) {
          <mat-icon svgIcon="mat_outline:menu"></mat-icon>
          }
          @if (showMobileMenu) {
          <mat-icon svgIcon="mat_outline:close"></mat-icon>
          }
        </button>
      </div>
      <div class="hidden lg:flex lg:flex-1 lg:items-center md:justify-between">
        <nav class="flex space-x-2">
          <a routerLink="/" class="menu-item" routerLinkActive="bg-gray-900 text-white"
            [routerLinkActiveOptions]="{exact:true}">Dashboard</a>
          <!-- Shipments -->
          <div>
            <button type="button" class="menu-item" [class.bg-gray-900]="isShipments" [class.text-white]="isShipments"
              aria-expanded="false" (click)="toggleMenu(navMenuEnum.Shipments)">
              <span>Shipments</span>
              <mat-icon class="text-gray-300 ml-2 group-hover:text-gray-500"
                svgIcon="mat_outline:expand_more"></mat-icon>
            </button>
            @if (openMenu == navMenuEnum.Shipments) {
            <div
              class="hidden md:block absolute top-full inset-x-0 ease-in-out transition-all duration-300 shadow-lg bg-white">
              <div
                class="max-w-7xl mx-auto grid gap-y-6 px-4 py-6 sm:grid-cols-3 sm:gap-8 sm:px-6 sm:py-8 lg:grid-cols-3 lg:px-8 lg:py-8">
                <!-- View Shipments -->
                <a routerLink="shipments" class="-m-3 p-3 flex flex-col justify-between rounded-lg hover:bg-gray-50">
                  <div class="flex md:h-full lg:flex-col">
                    <div class="flex-shrink-0">
                      <span
                        class="inline-flex items-center justify-center h-10 w-10 rounded-md bg-blue-500 text-white sm:h-12 sm:w-12">
                        <i class="far fa-shipping-fast"></i>
                      </span>
                    </div>
                    <div class="ml-4 md:flex-1 md:flex md:flex-col md:justify-between lg:ml-0 lg:mt-4">
                      <div>
                        <p class="text-gray-900">
                          View Shipments
                        </p>
                        <p class="mt-1 text-sm text-gray-500">
                          View all Scheduled, Enroute and Delivered Shipments
                        </p>
                      </div>
                    </div>
                  </div>
                </a>
                <!-- Quotes -->
                <div class="-m-3 p-3 flex flex-col justify-between rounded-lg hover:bg-gray-50 cursor-pointer"
                  *hasPermission="[permissions.SHIPMENTS_CREATE]">
                  <a routerLink="shipments/quotes" class="flex md:h-full lg:flex-col">
                    <div class="flex-shrink-0">
                      <span
                        class="inline-flex items-center justify-center h-10 w-10 rounded-md bg-blue-500 text-white sm:h-12 sm:w-12">
                        <mat-icon svgIcon="mat_outline:request_quote" class="text-white"></mat-icon>
                      </span>
                    </div>
                    <div class="ml-4 md:flex-1 md:flex md:flex-col md:justify-between lg:ml-0 lg:mt-4">
                      <div>
                        <p class="text-gray-900">
                          Quotes
                        </p>
                        <p class="mt-1 text-sm text-gray-500">
                          View all saved shipment quotes & schedule a shipment
                        </p>
                      </div>
                    </div>
                  </a>
                  <div class="flex gap-4">
                    <a routerLink="shipments/quotes/quick" class="mt-2 text-sm font-medium text-indigo-600 lg:mt-4"
                      *hasPermission="[permissions.ADMIN_ALL]">Quick Quote</a>
                    <a routerLink="shipments/quotes/multi" class="mt-2 text-sm font-medium text-indigo-600 lg:mt-4"
                      *hasPermission="[permissions.ADMIN_ALL]">Multi Quote</a>
                    <a routerLink="shipments/quotes/zip" class="mt-2 text-sm font-medium text-indigo-600 lg:mt-4"
                      *hasPermission="[permissions.SHIPMENTS_ZIPQUOTE]">Zip Quote</a>
                  </div>
                </div>
                <!-- Import Shipments -->
                @if (userInfo.IsASM) {
                <a routerLink="/shipments/import"
                  class="-m-3 p-3 flex flex-col justify-between rounded-lg hover:bg-gray-50"
                  *hasPermission="[permissions.SHIPMENTS_CREATE]">
                  <div class="flex md:h-full lg:flex-col">
                    <div class="flex-shrink-0">
                      <span
                        class="inline-flex items-center justify-center h-10 w-10 rounded-md bg-blue-500 text-white sm:h-12 sm:w-12">
                        <i class="far fa-file-excel"></i>
                      </span>
                    </div>
                    <div class="ml-4 md:flex-1 md:flex md:flex-col md:justify-between lg:ml-0 lg:mt-4">
                      <div>
                        <p class="text-gray-900">
                          Import Shipments
                        </p>
                        <p class="mt-1 text-sm text-gray-500">
                          Bulk import shipments using an Excel template
                        </p>
                      </div>
                    </div>
                  </div>
                </a>
                }
              </div>
              <div class="bg-gray-50" *hasPermission="[permissions.SHIPMENTS_CREATE]">
                <div class="max-w-7xl mx-auto space-y-6 px-4 py-5 sm:flex sm:space-y-0 sm:space-x-10 sm:px-6 lg:px-8">
                  <div class="flow-root">
                    <a routerLink="shipments/new"
                      class="-m-3 p-3 flex items-center rounded-md text-gray-900 hover:bg-gray-100">
                      <mat-icon svgIcon="heroicons_outline:plus-circle"></mat-icon>
                      <span class="ml-3">Add Shipment</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            }
          </div>
          <!-- Carriers -->
          <div>
            <button type="button" class="menu-item" [class.bg-gray-900]="isCarriers" [class.text-white]="isCarriers"
              aria-expanded="false" (click)="toggleMenu(navMenuEnum.Carriers)">
              <span>Carriers</span>
              <mat-icon class="text-gray-300 ml-2 group-hover:text-gray-500"
                svgIcon="mat_outline:expand_more"></mat-icon>
            </button>
            @if (openMenu == navMenuEnum.Carriers) {
            <div
              class="hidden md:block absolute top-full inset-x-0 ease-in-out transition-all duration-300 shadow-lg bg-white">
              <div
                class="max-w-7xl mx-auto grid gap-y-6 px-4 py-6 sm:grid-cols-2 sm:gap-8 sm:px-6 sm:py-8 lg:grid-cols-3 lg:px-8 lg:py-8">
                @if (userInfo.IsASM) {
                <a routerLink="/carriers/accounts"
                  class="-m-3 p-3 flex flex-col justify-between rounded-lg hover:bg-gray-50">
                  <div class="flex md:h-full lg:flex-col">
                    <div class="flex-shrink-0">
                      <span
                        class="inline-flex items-center justify-center h-10 w-10 rounded-md bg-blue-500 text-white sm:h-12 sm:w-12">
                        <i class="far fa-truck"></i>
                      </span>
                    </div>
                    <div class="ml-4 md:flex-1 md:flex md:flex-col md:justify-between lg:ml-0 lg:mt-4">
                      <div>
                        <p class="text-gray-900">
                          Accounts
                        </p>
                        <p class="mt-1 text-sm text-gray-500">
                          View, add and modify carrier account information
                        </p>
                      </div>
                    </div>
                  </div>
                </a>
                }
                <a routerLink="/carriers/summary"
                  class="-m-3 p-3 flex flex-col justify-between rounded-lg hover:bg-gray-50">
                  <div class="flex md:h-full lg:flex-col">
                    <div class="flex-shrink-0">
                      <span
                        class="inline-flex items-center justify-center h-10 w-10 rounded-md bg-blue-500 text-white sm:h-12 sm:w-12">
                        <mat-icon svgIcon="mat_outline:percent" class="text-white"></mat-icon>
                      </span>
                    </div>
                    <div class="ml-4 md:flex-1 md:flex md:flex-col md:justify-between lg:ml-0 lg:mt-4">
                      <div>
                        <p class="text-gray-900">
                          Summary
                        </p>
                        <p class="mt-1 text-sm text-gray-500">
                          View summary of loads by carrier including on time percentages
                        </p>
                      </div>
                    </div>
                  </div>
                </a>
                @if (userInfo.IsASM) {
                <a routerLink="/carriers/prepro-summary"
                  class="-m-3 p-3 flex flex-col justify-between rounded-lg hover:bg-gray-50">
                  <div class="flex md:h-full lg:flex-col">
                    <div class="flex-shrink-0">
                      <span
                        class="inline-flex items-center justify-center h-10 w-10 rounded-md bg-blue-500 text-white sm:h-12 sm:w-12">
                        <i class="far fa-hashtag"></i>
                      </span>
                    </div>
                    <div class="ml-4 md:flex-1 md:flex md:flex-col md:justify-between lg:ml-0 lg:mt-4">
                      <div>
                        <p class="text-gray-900">
                          Pre Pros
                        </p>
                        <p class="mt-1 text-sm text-gray-500">
                          View, add and modify carrier pre pro numbers
                        </p>
                      </div>
                    </div>
                  </div>
                </a>
                }
                <!-- <a *hasPermission="[permissions.ADMIN_ALL]" routerLink="/carriers/lookup" class="-m-3 p-3 flex flex-col justify-between rounded-lg hover:bg-gray-50">
                    <div class="flex md:h-full lg:flex-col">
                      <div class="flex-shrink-0">
                        <span class="inline-flex items-center justify-center h-10 w-10 rounded-md bg-blue-500 text-white sm:h-12 sm:w-12">
                          <mat-icon svgIcon="mat_outline:screen_search_desktop"></mat-icon>
                        </span>
                      </div>
                      <div class="ml-4 md:flex-1 md:flex md:flex-col md:justify-between lg:ml-0 lg:mt-4">
                        <div>
                          <p class="text-gray-900"> Lookup </p>
                          <p class="mt-1 text-sm text-gray-500"> Search & Email Carriers </p>
                        </div>
                      </div>
                    </div>
                  </a> -->
              </div>
            </div>
            }
          </div>
          <!-- Locations -->
          <div>
            <button type="button" class="menu-item" [class.bg-gray-900]="isLocations" [class.text-white]="isLocations"
              aria-expanded="false" (click)="toggleMenu(navMenuEnum.Locations)">
              <span>Locations</span>
              <mat-icon class="text-gray-300 ml-2 group-hover:text-gray-500"
                svgIcon="mat_outline:expand_more"></mat-icon>
            </button>
            @if (openMenu == navMenuEnum.Locations) {
            <div
              class="hidden md:block absolute top-full inset-x-0 ease-in-out transition-all duration-300 shadow-lg bg-white">
              <div
                class="max-w-7xl mx-auto grid gap-y-6 px-4 py-6 sm:grid-cols-2 sm:gap-8 sm:px-6 sm:py-8 lg:grid-cols-2 lg:px-8 lg:py-8">
                <a routerLink="/locations" class="-m-3 p-3 flex flex-col justify-between rounded-lg hover:bg-gray-50">
                  <div class="flex md:h-full lg:flex-col">
                    <div class="flex-shrink-0">
                      <span
                        class="inline-flex items-center justify-center h-10 w-10 rounded-md bg-blue-500 text-white sm:h-12 sm:w-12">
                        <mat-icon svgIcon="mat_outline:place" class="text-white"></mat-icon>
                      </span>
                    </div>
                    <div class="ml-4 md:flex-1 md:flex md:flex-col md:justify-between lg:ml-0 lg:mt-4">
                      <div>
                        <p class="text-gray-900">
                          View Locations
                        </p>
                        <p class="mt-1 text-sm text-gray-500">
                          View, Add and Modify Locations
                        </p>
                      </div>
                    </div>
                  </div>
                </a>
                <a routerLink="/locations/import"
                  class="-m-3 p-3 flex flex-col justify-between rounded-lg hover:bg-gray-50">
                  <div class="flex md:h-full lg:flex-col">
                    <div class="flex-shrink-0">
                      <span
                        class="inline-flex items-center justify-center h-10 w-10 rounded-md bg-blue-500 text-white sm:h-12 sm:w-12">
                        <i class="far fa-file-excel"></i>
                      </span>
                    </div>
                    <div class="ml-4 md:flex-1 md:flex md:flex-col md:justify-between lg:ml-0 lg:mt-4">
                      <div>
                        <p class="text-gray-900">
                          Import Locations
                        </p>
                        <p class="mt-1 text-sm text-gray-500">
                          Import bulk locations using an Excel template
                        </p>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
              <div class="bg-gray-50">
                <div class="max-w-7xl mx-auto space-y-6 px-4 py-5 sm:flex sm:space-y-0 sm:space-x-10 sm:px-6 lg:px-8">
                  <div class="flow-root">
                    <a (click)="addLocation()"
                      class="-m-3 p-3 flex items-center rounded-md text-gray-900 hover:bg-gray-100">
                      <mat-icon svgIcon="heroicons_outline:plus-circle"></mat-icon>
                      <span class="ml-3 cursor-pointer">Add Location</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            }
          </div>
          <!-- Claims -->
          <a routerLink="/claims" class="menu-item" routerLinkActive="bg-gray-900 text-white">Claims</a>
          <!-- Reports -->
          <a *hasPermission="[permissions.REPORTS_VIEW]" routerLink="/reports" class="menu-item"
            routerLinkActive="bg-gray-900 text-white">Reports</a>
          <!-- Admin -->
          <div>
            <button type="button" class="menu-item" [class.bg-gray-900]="isAdmin" [class.text-white]="isAdmin"
              aria-expanded="false" (click)="toggleMenu(navMenuEnum.Admin)">
              <span>Admin</span>
              <mat-icon class="text-gray-300 ml-2 group-hover:text-gray-500"
                svgIcon="mat_outline:expand_more"></mat-icon>
            </button>
            @if (openMenu == navMenuEnum.Admin) {
            <div
              class="hidden md:block absolute top-full inset-x-0 ease-in-out transition-all duration-300 shadow-lg bg-white">
              <div
                class="max-w-7xl mx-auto grid gap-y-6 px-4 py-6 sm:grid-cols-2 sm:gap-8 sm:px-6 sm:py-8 lg:grid-cols-4 lg:px-8 lg:py-8">
                <ng-container *hasPermission="[permissions.ADMIN_ALL]">
                  <a routerLink="/admin/customers"
                    class="-m-3 p-3 flex flex-col justify-between rounded-lg hover:bg-gray-50">
                    <div class="flex md:h-full lg:flex-col">
                      <div class="flex-shrink-0">
                        <span
                          class="inline-flex items-center justify-center h-10 w-10 rounded-md bg-blue-500 text-white sm:h-12 sm:w-12">
                          <mat-icon svgIcon="mat_outline:business" class="text-white"></mat-icon>
                        </span>
                      </div>
                      <div class="ml-4 md:flex-1 md:flex md:flex-col md:justify-between lg:ml-0 lg:mt-4">
                        <div>
                          <p class="text-gray-900">
                            Customers
                          </p>
                          <p class="mt-1 text-sm text-gray-500">
                            View, Add and Modify Customers
                          </p>
                        </div>
                      </div>
                    </div>
                  </a>
                  <div class="-m-3 p-3 flex flex-col justify-between rounded-lg hover:bg-gray-50">
                    <a routerLink="/admin/invoicing/approvals" class="flex md:h-full lg:flex-col">
                      <div class="flex-shrink-0">
                        <span
                          class="inline-flex items-center justify-center h-10 w-10 rounded-md bg-blue-500 text-white sm:h-12 sm:w-12">
                          <mat-icon svgIcon="mat_outline:check_box" class="text-white"></mat-icon>
                        </span>
                      </div>
                      <div class="ml-4 md:flex-1 md:flex md:flex-col md:justify-between lg:ml-0 lg:mt-4">
                        <div>
                          <p class="text-gray-900">
                            Invoicing
                          </p>
                          <p class="mt-1 text-sm text-gray-500">
                            Approve and View Invoices
                          </p>
                        </div>
                      </div>
                    </a>
                    <div class="mt-1 text-sm text-gray-500">
                      <a routerLink="/admin/invoicing/approvals" class="link mr-4">Approvals</a>
                      <a routerLink="/admin/invoicing/generated" class="link">Generated</a>
                    </div>
                  </div>
                  <a routerLink="/admin/nmfcs"
                    class="-m-3 p-3 flex flex-col justify-between rounded-lg hover:bg-gray-50">
                    <div class="flex md:h-full lg:flex-col">
                      <div class="flex-shrink-0">
                        <span
                          class="inline-flex items-center justify-center h-10 w-10 rounded-md bg-blue-500 text-white sm:h-12 sm:w-12">
                          <i class="far fa-hashtag"></i>
                        </span>
                      </div>
                      <div class="ml-4 md:flex-1 md:flex md:flex-col md:justify-between lg:ml-0 lg:mt-4">
                        <div>
                          <p class="text-gray-900">
                            NMFCs
                          </p>
                          <p class="mt-1 text-sm text-gray-500">
                            Manage NMFCs available when quoting shipments
                          </p>
                        </div>
                      </div>
                    </div>
                  </a>
                  <a routerLink="/admin/pricing"
                    class="-m-3 p-3 flex flex-col justify-between rounded-lg hover:bg-gray-50">
                    <div class="flex md:h-full lg:flex-col">
                      <div class="flex-shrink-0">
                        <span
                          class="inline-flex items-center justify-center h-10 w-10 rounded-md bg-blue-500 text-white sm:h-12 sm:w-12">
                          <i class="far fa-file-invoice-dollar"></i>
                        </span>
                      </div>
                      <div class="ml-4 md:flex-1 md:flex md:flex-col md:justify-between lg:ml-0 lg:mt-4">
                        <div>
                          <p class="text-gray-900">
                            Pricing & Contracts
                          </p>
                          <p class="mt-1 text-sm text-gray-500">
                            Manage customer pricing contracts
                          </p>
                        </div>
                      </div>
                    </div>
                  </a>
                  <a routerLink="/admin/users"
                    class="-m-3 p-3 flex flex-col justify-between rounded-lg hover:bg-gray-50">
                    <div class="flex md:h-full lg:flex-col">
                      <div class="flex-shrink-0">
                        <span
                          class="inline-flex items-center justify-center h-10 w-10 rounded-md bg-blue-500 text-white sm:h-12 sm:w-12">
                          <mat-icon svgIcon="mat_outline:people" class="text-white"></mat-icon>
                        </span>
                      </div>
                      <div class="ml-4 md:flex-1 md:flex md:flex-col md:justify-between lg:ml-0 lg:mt-4">
                        <div>
                          <p class="text-gray-900">
                            Users
                          </p>
                          <p class="mt-1 text-sm text-gray-500">
                            Manage users
                          </p>
                        </div>
                      </div>
                    </div>
                  </a>
                  <a routerLink="/admin/user-summary"
                    class="-m-3 p-3 flex flex-col justify-between rounded-lg hover:bg-gray-50">
                    <div class="flex md:h-full lg:flex-col">
                      <div class="flex-shrink-0">
                        <span
                          class="inline-flex items-center justify-center h-10 w-10 rounded-md bg-blue-500 text-white sm:h-12 sm:w-12">
                          <i class="fas fa-chart-line"></i>
                        </span>
                      </div>
                      <div class="ml-4 md:flex-1 md:flex md:flex-col md:justify-between lg:ml-0 lg:mt-4">
                        <div>
                          <p class="text-gray-900">
                            User Summary
                          </p>
                          <p class="mt-1 text-sm text-gray-500">
                            See load totals by user
                          </p>
                        </div>
                      </div>
                    </div>
                  </a>
                  <a routerLink="/admin/bulk-custom-loads"
                    class="-m-3 p-3 flex flex-col justify-between rounded-lg hover:bg-gray-50">
                    <div class="flex md:h-full lg:flex-col">
                      <div class="flex-shrink-0">
                        <span
                          class="inline-flex items-center justify-center h-10 w-10 rounded-md bg-blue-500 text-white sm:h-12 sm:w-12">
                          <i class="far fa-file-excel"></i>
                        </span>
                      </div>
                      <div class="ml-4 md:flex-1 md:flex md:flex-col md:justify-between lg:ml-0 lg:mt-4">
                        <div>
                          <p class="text-gray-900">
                            Import Custom Loads
                          </p>
                          <p class="mt-1 text-sm text-gray-500">
                            Import bulk custom loads using an Excel template
                          </p>
                        </div>
                      </div>
                    </div>
                  </a>
                  <a routerLink="/admin/edi" class="-m-3 p-3 flex flex-col justify-between rounded-lg hover:bg-gray-50">
                    <div class="flex md:h-full lg:flex-col">
                      <div class="flex-shrink-0">
                        <span
                          class="inline-flex items-center justify-center h-10 w-10 rounded-md bg-blue-500 text-white sm:h-12 sm:w-12">
                          <i class="far fa-toilet-paper-alt"></i>
                        </span>
                      </div>
                      <div class="ml-4 md:flex-1 md:flex md:flex-col md:justify-between lg:ml-0 lg:mt-4">
                        <div>
                          <p class="text-gray-900">
                            EDI Variances
                          </p>
                          <p class="mt-1 text-sm text-gray-500">
                            View EDI Variances
                          </p>
                        </div>
                      </div>
                    </div>
                  </a>
                  <a href="https://cobrowse.io/dashboard" target="_blank"
                    class="-m-3 p-3 flex flex-col justify-between rounded-lg hover:bg-gray-50">
                    <div class="flex md:h-full lg:flex-col">
                      <div class="flex-shrink-0">
                        <span
                          class="inline-flex items-center justify-center h-10 w-10 rounded-md bg-blue-500 text-white sm:h-12 sm:w-12">
                          <i class="fa-solid fa-screen-users"></i>
                        </span>
                      </div>
                      <div class="ml-4 md:flex-1 md:flex md:flex-col md:justify-between lg:ml-0 lg:mt-4">
                        <div>
                          <p class="text-gray-900">
                            Cobrowse
                          </p>
                          <p class="mt-1 text-sm text-gray-500">
                            Assist Users remotely by viewing their browser
                          </p>
                        </div>
                      </div>
                    </div>
                  </a>
                </ng-container>
                <a routerLink="/admin/packages"
                  class="-m-3 p-3 flex flex-col justify-between rounded-lg hover:bg-gray-50">
                  <div class="flex md:h-full lg:flex-col">
                    <div class="flex-shrink-0">
                      <span
                        class="inline-flex items-center justify-center h-10 w-10 rounded-md bg-blue-500 text-white sm:h-12 sm:w-12">
                        <i class="fa fa-solid fa-box"></i>
                      </span>
                    </div>
                    <div class="ml-4 md:flex-1 md:flex md:flex-col md:justify-between lg:ml-0 lg:mt-4">
                      <div>
                        <p class="text-gray-900">
                          Packages
                        </p>
                        <p class="mt-1 text-sm text-gray-500">
                          Predefined Packages and Products
                        </p>
                      </div>
                    </div>
                  </div>
                </a>
                <a routerLink="/admin/assets"
                  class="-m-3 p-3 flex flex-col justify-between rounded-lg hover:bg-gray-50">
                  <div class="flex md:h-full lg:flex-col">
                    <div class="flex-shrink-0">
                      <span
                        class="inline-flex items-center justify-center h-10 w-10 rounded-md bg-blue-500 text-white sm:h-12 sm:w-12">
                        <i class="fa fa-solid fa-warehouse-full"></i>
                      </span>
                    </div>
                    <div class="ml-4 md:flex-1 md:flex md:flex-col md:justify-between lg:ml-0 lg:mt-4">
                      <div>
                        <p class="text-gray-900">
                          Asset Inventory
                        </p>
                        <p class="mt-1 text-sm text-gray-500">
                          Manage Assets for all locations
                        </p>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            </div>
            }
          </div>
        </nav>
        <div class="flex items-center gap-2">
          <button *hasPermission="[permissions.SMARTINBOX_READ];Strict: true" routerLink="mailbox" type="button"
            class="inline-flex items-center p-1 border border-transparent rounded-full shadow-sm text-black bg-gray-100 hover:bg-blue-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
            @if (needsQuoteCount !== 0) {
            <mat-icon id="smartInboxQuoteCount" class="cursor-pointer text-gray-900" matTooltip="Smart Inbox"
              [matBadge]="needsQuoteCount" matBadgeColor="accent" [class.small-badge-text]="needsQuoteCount > 999"
              aria-hidden="false">inbox</mat-icon>
            }
            @else {
            <mat-icon id="smartInboxQuoteCount" class="cursor-pointer text-gray-900"
              matTooltip="Smart Inbox">inbox</mat-icon>
            }
          </button>
          <button type="button"
            class="inline-flex items-center p-1 border border-transparent rounded-full shadow-sm text-black bg-gray-100 hover:bg-blue-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
            <mat-icon class="cursor-pointer text-gray-900"
              matTooltip="System Wide Search. Tip: Use CTRL+S to launch search" (click)="showSearch()">search</mat-icon>
          </button>
          @if (userInfo.IsASM) {
          <sn-notification-slide-out></sn-notification-slide-out>
          }
          <div class="relative">
            <img [src]="userInfo.profilePicture" class="rounded-full w-9 h-9 cursor-pointer" [matMenuTriggerFor]="profile" matTooltip="Click to view Profile Menu">
            <span class="absolute right-0 bottom-0 w-2.5 h-2.5 rounded-full mr-px mb-px"
              [matTooltip]="signalrStatus"
              [ngClass]="{
                'bg-green-500': signalrStatus === 'Connected',
                'bg-amber-500': signalrStatus === 'Connecting',
                'bg-red-500': signalrStatus === 'Disconnected' }"></span>
          </div>
          <mat-menu #profile="matMenu">
            <a mat-menu-item routerLink="my-profile">My Profile</a>
            <a mat-menu-item (click)="showWhatsNewModal()">What's New</a>
            @if (!userInfo.IsASM) {
            <a mat-menu-item (click)="resetPassword()">Reset Password</a>
            }
            <a mat-menu-item *hasPermission="[permissions.DEV_ALL]" routerLink="developers">Developers</a>
            <a mat-menu-item (click)="logout()">Logout</a>
          </mat-menu>
        </div>
      </div>
    </div>
  </div>
  <!-- Mobile Menu -->
  @if (showMobileMenu) {
  <div class="lg:hidden" id="mobile-menu">
    <div class="px-2 pt-2 pb-3 space-y-1">
      <a routerLink="/" class="menu-item block" routerLinkActive="bg-gray-900 text-white"
        [routerLinkActiveOptions]="{exact:true}">Dashboard</a>
      <a (click)="toggleMenu(navMenuEnum.Shipments)" class="menu-item block" [class.bg-gray-900]="isShipments"
        [class.text-white]="isShipments">Shipments</a>
      @if (openMenu == navMenuEnum.Shipments) {
      <ul>
        <li routerLink="shipments" class="mobile-child">View Shipment</li>
        <li routerLink="shipments/new" class="mobile-child" *hasPermission="[permissions.SHIPMENTS_CREATE]">New Shipment
        </li>
        <li routerLink="shipments/quotes" class="mobile-child" *hasPermission="[permissions.SHIPMENTS_CREATE]">Quotes
        </li>
        <li routerLink="shipments/quotes/quick" class="mobile-child" *hasPermission="[permissions.ADMIN_ALL]">Quick
          Quote</li>
        <li routerLink="shipments/quotes/multi" class="mobile-child" *hasPermission="[permissions.ADMIN_ALL]">Multi
          Quote</li>
      </ul>
      }
      <a (click)="toggleMenu(navMenuEnum.Carriers)" class="menu-item block" [class.bg-gray-900]="isCarriers"
        [class.text-white]="isCarriers">Carriers</a>
      @if (openMenu == navMenuEnum.Carriers) {
      <ul>
        @if (userInfo.IsASM) {
        <li routerLink="carriers/accounts" class="mobile-child">Accounts</li>
        }
        <li routerLink="carriers/summary" class="mobile-child">Summary</li>
      </ul>
      }
      <a (click)="toggleMenu(navMenuEnum.Locations)" class="menu-item block" [class.bg-gray-900]="isLocations"
        [class.text-white]="isLocations">Locations</a>
      @if (openMenu == navMenuEnum.Locations) {
      <ul>
        <li routerLink="locations" class="mobile-child">Accounts</li>
        <li routerLink="locations/import" class="mobile-child">Summary</li>
      </ul>
      }
      <a routerLink="/claims" class="menu-item block" routerLinkActive="bg-gray-900 text-white">Claims</a>
      @if (userInfo.IsASM) {
      <a routerLink="/reports" class="menu-item block" routerLinkActive="bg-gray-900 text-white">Reports</a>
      }
      <div>
        <h3 class="text-sm font-medium tracking-wide text-gray-500 uppercase px-3 pt-2 pb-3">
          Admin
        </h3>
        <div class="flex flex-col gap-2">
          <ng-container *hasPermission="[permissions.ADMIN_ALL]">
            <a routerLink="/admin/customers" class="mobile-child">Customers</a>
            @if (true) {
            <a routerLink="/admin/edi" class="mobile-child">EDI</a>
            }
            <a routerLink="/admin/invoicing/generated" class="mobile-child">Generated Invoices</a>
            <a routerLink="/admin/invoicing/approvals" class="mobile-child">Invoice Approvals</a>
            <a routerLink="/admin/nmfcs" class="mobile-child">NMFCs</a>
            <a routerLink="/admin/pricing" class="mobile-child">Pricing & Contracts</a>
            <a routerLink="/admin/users" class="mobile-child">Users</a>
            <a routerLink="/admin/user-summary" class="mobile-child">User Summary</a>
          </ng-container>
          <a routerLink="/admin/packages" class="mobile-child">Packages</a>
        </div>
      </div>
      <a *hasPermission="[permissions.SMARTINBOX_READ];Strict: true" routerLink="mailbox" class="menu-item block"
        routerLinkActive="bg-gray-900 text-white">Smart Inbox</a>
      <a routerLink="my-profile" class="menu-item block" routerLinkActive="bg-gray-900 text-white">My Profile</a>
      <a (click)="showWhatsNewModal()" class="menu-item block" routerLinkActive="bg-gray-900 text-white">What's New</a>
      <a (click)="logout()" class="menu-item block" routerLinkActive="bg-gray-900 text-white">Logout</a>
    </div>
  </div>
  }
</nav>
}
<ng-template #searchDialog>
  <sn-search></sn-search>
</ng-template>
