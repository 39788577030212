import { CommonModule } from '@angular/common';
import { Component, OnInit, inject } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatPaginatorModule, PageEvent } from '@angular/material/paginator';
import { MatTableModule } from '@angular/material/table';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { LuxonModule } from 'luxon-angular';
import { EdiClient, EdiFileSummaryDto, EdiShipmentDataDto } from '../../../core/services/api.service';
import { RequestService } from '../../../core/services/request.service';
import { CodeModalComponent } from '../../../shared/code-modal/code-modal.component';

@Component({
    selector: 'sn-edi-file-detail',
    standalone: true,
    imports: [
        CommonModule,
        FormsModule,
        MatButtonModule,
        MatCardModule,
        MatDialogModule,
        MatIconModule,
        MatPaginatorModule,
        MatTableModule,
        LuxonModule,
        RouterModule
    ],
    templateUrl: './edi-file-detail.component.html',
    styleUrls: ['./edi-file-detail.component.scss']
})
export class EdiFileDetailComponent implements OnInit {
    private _route = inject(ActivatedRoute);
    private _ediClient = inject(EdiClient);
    private _requestService = inject(RequestService);
    private _matDialog = inject(MatDialog);

    filePath: string;
    summary: EdiFileSummaryDto;
    loads: EdiShipmentDataDto[] = [];
    totalCount = 0;
    pageSize = 100;
    pageNumber = 1;
    sortField = 'loadNumber';
    sortAscending = true;
    displayedColumns = ['loadNumber', 'result', 'variance', 'billedDate'];

    ngOnInit() {
        this.filePath = this._route.snapshot.params['filePath'];
        this.loadSummary();
        this.loadLoads();
    }

    loadSummary() {
        this._requestService.get(
            this._ediClient.edi_GetEdiFileSummary(this.filePath),
            summary => this.summary = summary,
            'EDI File Summary'
        );
    }

    loadLoads() {
        this._requestService.get(
            this._ediClient.edi_GetEdiFileLoads(
                this.filePath,
                this.pageNumber,
                this.pageSize,
                this.sortField,
                this.sortAscending
            ),
            response => {
                this.loads = response.records;
                this.totalCount = response.count;
            },
            'EDI File Loads'
        );
    }

    onSort(field: string) {
        if (this.sortField === field) {
            this.sortAscending = !this.sortAscending;
        } else {
            this.sortField = field;
            this.sortAscending = true;
        }
        this.loadLoads();
    }

    onPageEvent(event: PageEvent) {
        this.pageNumber = event.pageIndex + 1;
        this.pageSize = event.pageSize;
        this.loadLoads();
    }

    onLoadClick(load: EdiShipmentDataDto) {
        this._matDialog.open(CodeModalComponent, {
            data: { object: load, title: `EDI Data for Load ${load.loadNumber}` }
        });
    }

    onBackClick() {
        window.history.back();
    }

    getResultLabel(result: number): string {
        switch (result) {
            case 0: return 'Success';
            case 1: return 'Success with Variance';
            case 2: return 'Invalid Load Number';
            case 3: return 'Load Not Found';
            case 4: return 'Invalid Carrier';
            case 5: return 'Billing Failed';
            case 6: return 'Already Billed';
            default: return 'Unknown Error';
        }
    }
} 