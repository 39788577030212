import { Component, ElementRef, OnInit, inject } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatTableModule } from '@angular/material/table';
import { AuthService } from '@auth0/auth0-angular';
import { RequestService } from 'app/core/services/request.service';
import { FileUploadComponent } from 'app/shared/widgets/file-upload/file-upload.component';
import { DateTime } from 'luxon';
import { CustomerDto, CustomersClient, ImportFileColumnAttributeMetaData, ShipmentsClient } from '../../core/services/api.service';
import { SnackBarService } from '../../core/services/snack-bar.service';
import { UserInfo, UserResolverService } from '../../core/services/user-resolver.service';

@Component({
    templateUrl: "./shipment-import.component.html",
    styleUrls: ["./shipment-import.component.scss"],
    imports: [FormsModule, MatCardModule, MatFormFieldModule, ReactiveFormsModule, MatIconModule, MatExpansionModule, MatTableModule, FileUploadComponent]
})

export class ShipmentImportComponent implements OnInit {
  private _elementRef = inject(ElementRef);
  private _authService = inject(AuthService);
  private _shipmentClient = inject(ShipmentsClient);
  private _requestService = inject(RequestService);
  private _snackbarService = inject(SnackBarService);
  private _userResolverService = inject(UserResolverService);
  private _customerClient = inject(CustomersClient);

  readonly accept: string = ".xlsx,.XLSX";
  minShipDate = DateTime.now().toUTC().startOf('day').toJSDate();
  file: File | undefined;
  accessToken: string = "";
  userInfo: UserInfo | undefined;
  customer: CustomerDto | undefined;
  columnData: ImportFileColumnAttributeMetaData[] = [];
  displayedColumns: string[] = ["name", "description", "isRequired", "requirements", "exampleValues"];
  displayIsProcessing: boolean = false;

  constructor() {
  this._userResolverService.userInfo.subscribe(x => {
      this.userInfo = x;
      if (x.CustomerId) {
        this._customerClient.customers_Get(x.CustomerId).subscribe(c => this.customer = c);
      }
    });
  }

  ngOnInit(): void {
    // gray background page
    this._elementRef.nativeElement.ownerDocument.body.style.backgroundColor = "#FAFAFA";

    // get access token
    this._authService.getAccessTokenSilently().subscribe(x => this.accessToken = x);
    this._shipmentClient.shipments_GetBulkShipmentImportColumns().subscribe(x => this.columnData = x);
  }

  onFileInputChange(file: File) {
    this.file = file;

    this._requestService.save(this._shipmentClient.shipments_ProcessShipmentImportFile({ data: file, fileName: file.name }),
      x => {
        this.displayIsProcessing = true;
        this._snackbarService.open("Your file is currently being processed and you will be notified by email when complete.");
      }, 
      "Processing Shipment Import");
  }
}
