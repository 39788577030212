<mat-card *ngIf="summary">
    <mat-card-header class="flex justify-between items-center">
        <mat-card-title>{{summary.filePath}}</mat-card-title>
        <button mat-icon-button (click)="onBackClick()">
            <mat-icon>close</mat-icon>
        </button>
    </mat-card-header>
    <mat-card-content>
        <!-- Summary -->
        <div class="grid grid-cols-2 md:grid-cols-4 gap-4 mb-6 p-4 bg-gray-50 rounded">
            <div>
                <div class="text-sm text-gray-600">Carrier</div>
                <div>{{summary.carrierName}}</div>
            </div>
            <div>
                <div class="text-sm text-gray-600">Total Loads</div>
                <div>{{summary.totalLoads}}</div>
            </div>
            <div>
                <div class="text-sm text-gray-600">Total Carrier Cost</div>
                <div>{{summary.totalCarrierCost | currency}}</div>
            </div>
            <div>
                <div class="text-sm text-gray-600">Total Customer Cost</div>
                <div>{{summary.totalCustomerCost | currency}}</div>
            </div>
            <div>
                <div class="text-sm text-gray-600">Total Variance</div>
                <div>{{summary.totalVariance | currency}}</div>
            </div>
            <div>
                <div class="text-sm text-gray-600">Received Date</div>
                <div>{{summary.receivedDate | dateTimeToFormat:'MMMM dd, yyyy'}}</div>
            </div>
        </div>

        <!-- Result Summary -->
        <div class="mb-6">
            <h3 class="text-lg font-semibold mb-2">Processing Results</h3>
            <div class="grid grid-cols-2 md:grid-cols-4 gap-4">
                <div *ngFor="let result of summary.resultSummary" 
                    class="p-3 rounded" 
                    [ngClass]="{
                        'bg-green-100': result.result === 0,
                        'bg-yellow-100': result.result === 1,
                        'bg-red-100': result.result > 1
                    }">
                    <div class="text-sm">{{getResultLabel(result.result)}}</div>
                    <div class="text-2xl font-bold">{{result.count}}</div>
                </div>
            </div>
        </div>

        <!-- Loads Table -->
        <table mat-table [dataSource]="loads" class="w-full">
            <ng-container matColumnDef="loadNumber">
                <th mat-header-cell *matHeaderCellDef (click)="onSort('loadNumber')">
                    Load Number
                    <mat-icon *ngIf="sortField === 'loadNumber'">
                        {{sortAscending ? 'arrow_upward' : 'arrow_downward'}}
                    </mat-icon>
                </th>
                <td mat-cell *matCellDef="let load">
                    <a class="text-blue-600 hover:underline cursor-pointer" routerLink="{{load.id}}">
                        {{load.loadNumber}}
                    </a>
                </td>
            </ng-container>

            <ng-container matColumnDef="result">
                <th mat-header-cell *matHeaderCellDef (click)="onSort('result')">
                    Result
                    <mat-icon *ngIf="sortField === 'result'">
                        {{sortAscending ? 'arrow_upward' : 'arrow_downward'}}
                    </mat-icon>
                </th>
                <td mat-cell *matCellDef="let load">{{getResultLabel(load.result)}}</td>
            </ng-container>

            <ng-container matColumnDef="variance">
                <th mat-header-cell *matHeaderCellDef>Variance</th>
                <td mat-cell *matCellDef="let load">{{load.variance | currency}}</td>
            </ng-container>

            <ng-container matColumnDef="billedDate">
                <th mat-header-cell *matHeaderCellDef (click)="onSort('billedDate')">
                    Billed Date
                    <mat-icon *ngIf="sortField === 'billedDate'">
                        {{sortAscending ? 'arrow_upward' : 'arrow_downward'}}
                    </mat-icon>
                </th>
                <td mat-cell *matCellDef="let load">{{load.billedDate | dateTimeToFormat:'MMMM dd, yyyy'}}</td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>

        <mat-paginator 
            [length]="totalCount"
            [pageSize]="pageSize"
            [pageIndex]="pageNumber - 1"
            [pageSizeOptions]="[5, 10, 25, 50]"
            (page)="onPageEvent($event)">
        </mat-paginator>
    </mat-card-content>
</mat-card> 