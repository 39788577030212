<mat-card>
    <mat-card-header>
        <mat-card-title>EDI Load Details</mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <!-- Main Container -->
        <div *ngIf="!data" class="text-center text-gray-500">No data found</div>

        <div *ngIf="data">
            <!-- Main Details -->
            <div class="mt-8 flow-root">
                <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                        <div class="overflow-hidden shadow ring-1 ring-black/5 sm:rounded-lg">
                            <table class="min-w-full divide-y divide-gray-300">
                                <thead class="bg-gray-50">
                                    <tr>
                                        <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">Key</th>
                                        <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Value</th>
                                    </tr>
                                </thead>
                                <tbody class="divide-y divide-gray-200 bg-white">
                                    <tr *ngFor="let item of getMainDetails()">
                                        <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">{{item.key}}</td>
                                        <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                            <ng-container [ngSwitch]="item.type">
                                                <ng-container *ngSwitchCase="'boolean'">
                                                    <svg *ngIf="!item.value" class="inline h-5 w-5 text-red-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                                                    </svg>
                                                    <svg *ngIf="item.value" class="inline h-5 w-5 text-green-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                                                    </svg>
                                                </ng-container>
                                                <ng-container *ngSwitchCase="'date'">{{item.value | date:'yyyy-MM-ddTHH:mm:ss.SSSZ'}}</ng-container>
                                                <ng-container *ngSwitchDefault>{{item.value}}</ng-container>
                                            </ng-container>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Line Item Costs -->
            <h2 class="text-xl font-semibold mt-8">Line Item Costs</h2>
            <div class="mt-8 flow-root">
                <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                        <div class="overflow-hidden shadow ring-1 ring-black/5 sm:rounded-lg">
                            <table class="min-w-full divide-y divide-gray-300">
                                <thead class="bg-gray-50">
                                    <tr>
                                        <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">AssignedNumber</th>
                                        <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">ChargeCode</th>
                                        <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">SpecialChargeDescription</th>
                                        <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Amount</th>
                                        <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">AmountCharged</th>
                                        <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">TrackingNumber</th>
                                    </tr>
                                </thead>
                                <tbody class="divide-y divide-gray-200 bg-white">
                                    <tr *ngFor="let item of data.lineItemCosts">
                                        <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">{{item.assignedNumber}}</td>
                                        <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{item.chargeCode}}</td>
                                        <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{item.specialChargeDescription}}</td>
                                        <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{item.amount}}</td>
                                        <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{item.amountCharged}}</td>
                                        <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{item.trackingNumber}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Line Items -->
            <h2 class="text-xl font-semibold mt-8">Line Items</h2>
            <div class="mt-8 flow-root">
                <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                        <div class="overflow-hidden shadow ring-1 ring-black/5 sm:rounded-lg">
                            <table class="min-w-full divide-y divide-gray-300">
                                <thead class="bg-gray-50">
                                    <tr>
                                        <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">AssignedNumber</th>
                                        <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Quantity</th>
                                        <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">TypeofServiceCode</th>
                                        <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">WeightUnitCode</th>
                                        <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">DunnageDescription</th>
                                        <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">PackagingFormCode</th>
                                        <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">LadingQuantity</th>
                                        <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">VolumeUnitQualifier</th>
                                        <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">WeightQualifier</th>
                                        <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Weight</th>
                                        <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">BilledRatedasQualifier</th>
                                        <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">BilledRatedasQuantity</th>
                                        <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">LadingLineItemNumber</th>
                                        <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Volume</th>
                                        <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">YesNoConditionorResponseCode</th>
                                        <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">TrackingNumber</th>
                                    </tr>
                                </thead>
                                <tbody class="divide-y divide-gray-200 bg-white">
                                    <tr *ngFor="let item of data.lineItems">
                                        <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">{{item.assignedNumber}}</td>
                                        <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{item.quantity}}</td>
                                        <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{item.typeofServiceCode}}</td>
                                        <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{item.weightUnitCode}}</td>
                                        <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{item.dunnageDescription}}</td>
                                        <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{item.packagingFormCode}}</td>
                                        <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{item.ladingQuantity}}</td>
                                        <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{item.volumeUnitQualifier}}</td>
                                        <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{item.weightQualifier}}</td>
                                        <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{item.weight}}</td>
                                        <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{item.billedRatedasQualifier}}</td>
                                        <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{item.billedRatedasQuantity}}</td>
                                        <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{item.ladingLineItemNumber}}</td>
                                        <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{item.volume}}</td>
                                        <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{item.yesNoConditionorResponseCode}}</td>
                                        <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{item.trackingNumber}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Description Marks -->
            <h2 class="text-xl font-semibold mt-8">Description Marks</h2>
            <div class="mt-8 flow-root">
                <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                        <div class="overflow-hidden shadow ring-1 ring-black/5 sm:rounded-lg">
                            <table class="min-w-full divide-y divide-gray-300">
                                <thead class="bg-gray-50">
                                    <tr>
                                        <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">AssignedNumber</th>
                                        <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">LadingLineItemNumber</th>
                                        <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">LadingDescription</th>
                                        <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">CommodityCode</th>
                                        <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">CommodityCodeQualifier</th>
                                        <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">PackagingCode</th>
                                        <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">MarksandNumbers</th>
                                        <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">MarksandNumbersQualifier</th>
                                        <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">CompartmentIDCode</th>
                                        <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">TrackingNumber</th>
                                    </tr>
                                </thead>
                                <tbody class="divide-y divide-gray-200 bg-white">
                                    <tr *ngFor="let item of data.descriptionMarks">
                                        <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">{{item.assignedNumber}}</td>
                                        <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{item.ladingLineItemNumber}}</td>
                                        <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{item.ladingDescription}}</td>
                                        <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{item.commodityCode}}</td>
                                        <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{item.commodityCodeQualifier}}</td>
                                        <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{item.packagingCode}}</td>
                                        <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{item.marksandNumbers}}</td>
                                        <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{item.marksandNumbersQualifier}}</td>
                                        <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{item.compartmentIDCode}}</td>
                                        <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{item.trackingNumber}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Measurements -->
            <h2 class="text-xl font-semibold mt-8">Measurements</h2>
            <div class="mt-8 flow-root">
                <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                        <div class="overflow-hidden shadow ring-1 ring-black/5 sm:rounded-lg">
                            <table class="min-w-full divide-y divide-gray-300">
                                <thead class="bg-gray-50">
                                    <tr>
                                        <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">AssignedNumber</th>
                                        <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Length</th>
                                        <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Width</th>
                                        <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Height</th>
                                        <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">MeasurementUnitQualifier</th>
                                        <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Quantity</th>
                                        <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">IndustryCode</th>
                                        <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">TrackingNumber</th>
                                    </tr>
                                </thead>
                                <tbody class="divide-y divide-gray-200 bg-white">
                                    <tr *ngFor="let item of data.measurements">
                                        <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">{{item.assignedNumber}}</td>
                                        <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{item.length}}</td>
                                        <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{item.width}}</td>
                                        <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{item.height}}</td>
                                        <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{item.measurementUnitQualifier}}</td>
                                        <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{item.quantity}}</td>
                                        <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{item.industryCode}}</td>
                                        <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{item.trackingNumber}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Items -->
            <h2 class="text-xl font-semibold mt-8">Items</h2>
            <div class="mt-8 flow-root">
                <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                        <div class="overflow-hidden shadow ring-1 ring-black/5 sm:rounded-lg">
                            <table class="min-w-full divide-y divide-gray-300">
                                <thead class="bg-gray-50">
                                    <tr>
                                        <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">TrackingNumber</th>
                                        <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">EdiCost</th>
                                        <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">EdiWeight</th>
                                        <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">EdiLength</th>
                                        <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">EdiHeight</th>
                                        <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">EdiWidth</th>
                                        <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">IsRedimension</th>
                                    </tr>
                                </thead>
                                <tbody class="divide-y divide-gray-200 bg-white">
                                    <tr *ngFor="let item of data.items">
                                        <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">{{item.trackingNumber}}</td>
                                        <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{item.ediCost}}</td>
                                        <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{item.ediWeight}}</td>
                                        <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{item.ediLength}}</td>
                                        <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{item.ediHeight}}</td>
                                        <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{item.ediWidth}}</td>
                                        <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                            <svg *ngIf="!item.isRedimension" class="inline h-5 w-5 text-red-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                                            </svg>
                                            <svg *ngIf="item.isRedimension" class="inline h-5 w-5 text-green-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                                            </svg>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Tarrif References -->
            <h2 class="text-xl font-semibold mt-8">Tarrif References</h2>
            <div class="mt-8 flow-root">
                <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                        <div class="overflow-hidden shadow ring-1 ring-black/5 sm:rounded-lg">
                            <table class="min-w-full divide-y divide-gray-300">
                                <thead class="bg-gray-50">
                                    <tr>
                                        <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">AssignedNumber</th>
                                        <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">TariffNumber</th>
                                        <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">FreightClass</th>
                                        <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">LadingLineItemNumber</th>
                                    </tr>
                                </thead>
                                <tbody class="divide-y divide-gray-200 bg-white">
                                    <tr *ngIf="!data.tarrifReferences?.length">
                                        <td colspan="4" class="whitespace-nowrap py-4 pl-4 pr-3 text-sm text-gray-500">No Tarrif References available</td>
                                    </tr>
                                    <tr *ngFor="let item of data.tarrifReferences">
                                        <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">{{item.assignedNumber}}</td>
                                        <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{item.tariffNumber}}</td>
                                        <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{item.freightClass}}</td>
                                        <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{item.ladingLineItemNumber}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </mat-card-content>
</mat-card>