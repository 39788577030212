import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { LuxonModule } from 'luxon-angular';
import { EdiShipmentDataDto } from '../../core/services/api.service';

@Component({
    selector: 'sn-edi-load-selection-dialog',
    standalone: true,
    imports: [CommonModule, MatDialogModule, MatButtonModule, LuxonModule],
    template: `
        <h2 mat-dialog-title>Multiple Results Found</h2>
        <mat-dialog-content>
            <p class="mb-4">Multiple EDI loads were found. Please select one to view:</p>
            <div class="space-y-2">
                <div *ngFor="let load of data.loads" 
                     class="p-3 border rounded cursor-pointer hover:bg-gray-50"
                     (click)="onLoadSelect(load)">
                    <div class="font-medium">Load #{{load.loadNumber}}</div>
                    <div class="text-sm text-gray-500">File: {{load.filePath}}</div>
                    <div class="text-sm text-gray-500">Carrier: {{load.carrierName}}</div>
                    <div class="text-sm text-gray-500">Date: {{load.billedDate | dateTimeToFormat:'short'}}</div>
                </div>
            </div>
        </mat-dialog-content>
        <mat-dialog-actions align="end">
            <button mat-button mat-dialog-close>Cancel</button>
        </mat-dialog-actions>
    `
})
export class EdiLoadSelectionDialogComponent {
    constructor(
        @Inject(MAT_DIALOG_DATA) public data: { loads: EdiShipmentDataDto[] },
        private dialogRef: MatDialogRef<EdiLoadSelectionDialogComponent>
    ) {}

    onLoadSelect(load: EdiShipmentDataDto): void {
        this.dialogRef.close(load);
    }
} 