<h2 mat-dialog-title>What's New</h2>
<mat-dialog-content>
  <div class="font-sans my-6 rounded rich-text-editor">
    <!-- Accordion Source: https://tailwindcomponents.com/component/tailwind-css-accordion -->
    @for (notification of notifications; track notification; let i = $index) {
      <h2 (click)="toggle(i)">
        <button type="button" [class.header-first]="i == 0" [class.header]="i != 0" [class.header-selected]="isExpanded(i) || (expandedNotifications.length == 0 && i == 0)">
          <div class="flex flex-1 justify-between mr-2">
            <span>{{notification.title}}</span>
            <span class="text-gray-500">{{notification.publishedWhen | dateTimeToTimezone:'dateonly'}}</span>
          </div>
          <mat-icon>{{isExpanded(i) ? "expand_less" : "expand_more"}}</mat-icon>
        </button>
      </h2>
      @if (isExpanded(i)) {
        <div class="p-5 border border-gray-200" [innerHTML]="notification.content"></div>
      }
    }
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button
    mat-dialog-close
    cdkFocusInitial
    type="button"
    class="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
    Close
  </button>
</mat-dialog-actions>
