<mat-card>
    <mat-card-header>
        <mat-card-title>EDI Files</mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <!-- Filters -->
        <div class="flex gap-4 mb-4">
            <mat-form-field>
                <mat-label>Carrier</mat-label>
                <mat-select [(ngModel)]="filter.carrierKey" (selectionChange)="onFilterChange()">
                    <mat-option [value]="''">All Carriers</mat-option>
                    <mat-option *ngFor="let carrier of carriers" [value]="carrier.carrierKey">
                        {{carrier.carrierName}}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field>
                <mat-label>Load Number</mat-label>
                <input matInput type="number" [(ngModel)]="loadNumberSearch" (keyup.enter)="onLoadNumberSearch()">
                <button *ngIf="loadNumberSearch" matSuffix mat-icon-button (click)="onLoadNumberSearch()">
                    <mat-icon>search</mat-icon>
                </button>
            </mat-form-field>
        </div>

        <!-- Table -->
        <table mat-table [dataSource]="files" class="w-full">
            <ng-container matColumnDef="filePath">
                <th mat-header-cell *matHeaderCellDef (click)="onSort('filePath')">
                    File Path
                    <mat-icon *ngIf="filter.sortField === 'filePath'">
                        {{filter.sortAscending ? 'arrow_upward' : 'arrow_downward'}}
                    </mat-icon>
                </th>
                <td mat-cell *matCellDef="let file">
                    <a class="text-blue-600 hover:underline cursor-pointer" (click)="onFileClick(file)">
                        {{file.filePath}}
                    </a>
                </td>
            </ng-container>

            <ng-container matColumnDef="carrierKey">
                <th mat-header-cell *matHeaderCellDef (click)="onSort('carrierKey')">
                    Carrier
                    <mat-icon *ngIf="filter.sortField === 'carrierKey'">
                        {{filter.sortAscending ? 'arrow_upward' : 'arrow_downward'}}
                    </mat-icon>
                </th>
                <td mat-cell *matCellDef="let file">{{file.carrierKey}}</td>
            </ng-container>

            <ng-container matColumnDef="totalLoads">
                <th mat-header-cell *matHeaderCellDef>
                    Total Loads
                </th>
                <td mat-cell *matCellDef="let file">{{file.totalLoads}}</td>
            </ng-container>

            <ng-container matColumnDef="receivedDate">
                <th mat-header-cell *matHeaderCellDef (click)="onSort('receivedDate')">
                    Received Date
                    <mat-icon *ngIf="filter.sortField === 'receivedDate'">
                        {{filter.sortAscending ? 'arrow_upward' : 'arrow_downward'}}
                    </mat-icon>
                </th>
                <td mat-cell *matCellDef="let file">{{file.receivedDate | dateTimeToFormat:'MMMM dd, yyyy'}}</td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>

        <mat-paginator 
            [length]="totalCount"
            [pageSize]="filter.pageSize"
            [pageIndex]="filter.pageNumber - 1"
            [pageSizeOptions]="[5, 10, 25, 50]"
            (page)="onPageEvent($event)">
        </mat-paginator>
    </mat-card-content>
</mat-card> 