import { ChangeDetectionStrategy, Component, OnInit, inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { Notification } from '../../../core/services/api.service';
import { MatIconModule } from '@angular/material/icon';
import { DateTimeToTimezonePipe } from 'app/shared/pipes/date-time-to-timezone.pipe';


@Component({
    templateUrl: './whats-new-modal.component.html',
    styleUrls: ['./whats-new-modal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [MatDialogModule, MatIconModule, DateTimeToTimezonePipe]
})
export class WhatsNewModalComponent implements OnInit {
  notifications: Notification[];
  expandedNotifications: number[] = [0];

  constructor() {
    const data = inject(MAT_DIALOG_DATA);

    this.notifications = data;
  }

  ngOnInit(): void {
  }

  expand(index: number) {
    this.expandedNotifications.push(index);
  }

  collapse(index: number) {
    let arrIndex = this.expandedNotifications.findIndex(x => x == index);
    this.expandedNotifications.splice(arrIndex, 1);
  }

  isExpanded(index: number) {
    return this.expandedNotifications.includes(index);
  }

  toggle(index: number) {
    if(this.isExpanded(index)) {
      this.collapse(index);
    } else {
      this.expand(index);
    }
  }
}
